export const i18nHash = {
  Nutanix: {
    welcome: 'Welcome to Nutanix'
  },
  Vocabulary: {
    Customers: 'Customers',
    Education: 'Education',
    or: 'OR',
    Partners: 'Partners',
    Support: 'Support',
    typeToFilter: 'Type to filter...',
    Update: 'Update'
  },
  Providers: {
    lookingForHelp: 'Looking for Help ?',
    message: 'Welcome to Xi',
    noProviderMessage: 'Authentication providers not registered yet. Please setup providers before login.',
    nutanixProviderLoginMessage: 'Log in with your Nutanix Local Account'
  },
  LoginPage: {
    goback: 'Go Back to Providers',
    logIn: 'Log In',
    oktaLogin: 'Login with Okta',
    username: 'Username',
    password: 'Password',
    otherMethods: 'Login with other methods',
    loginWith: 'Login with %{connectorName}',
    loginWithCompanyId: 'Login with your Company ID',
    loginLocal: 'Log in with your Nutanix Local Account'
  },
  UsersList: {
    title: 'Viewing %{totalCount} of all users',
    columns: {
      name: 'Name',
      type: 'Type',
      lastLogin: 'Last Login %{timezone}'
    },
    actions: {
      create: 'Create Local User'
    }
  },
  ServiceAccountList: {
    title: 'Viewing %{totalCount} of all service accounts',
    columns: {
      name: 'Name',
      email: 'Email',
      description: 'Description',
      createdOn: 'Created On'
    },
    actions: {
      create: 'Create Service Account',
      deactivate: 'Deactivate',
      delete: 'Delete Service Account',
      manageCertificates: 'Manage Certificates'
    }
  },
  ErrorMessages: {
    error0: 'a no error',
    error1: 'an app error',
    error2: 'a canceled error',
    error3: 'a retry error',
    error4: 'a timeout error',
    error5: 'an authenticated error',
    error6: 'a bad request error',
    error7: 'a login failed error. The user name or password did not match',
    error8: 'a not authorized error',
    error9: 'a password expired error',
    error10: 'a user locked out error',
    error11: 'an invalid token error',
    error12: 'an invalid client error',
    error13: 'an invalid certificate credentials error',
    error14: 'an invalid CA chain error',
    error15: 'an invalid certificate error',
    error16: 'an invalid certificate algorithm error',
    error17: 'an invalid root certificate error',
    error18: 'an invalid service token error',
    error19: 'a user not found error',
    error20: 'a user password not set error',
    error21: 'a fail to get secure key error',
    error22: 'an invalid service account error',
    error23: 'a missing auto login config error',
    error24: 'a CAC not enabled error',
    error25: 'a missing directory config error',
    error26: 'a directory service error',
    error27: 'a CAC term not accepted error',
    error28: 'a complexity criteria error',
    error29: 'an account disabled error',
    error30: 'an account expired error',
    error31: 'an unknown error',
    error400: 'a issue that server could not understand the request',
    error401: 'a login failed error. The user name or password did not match',
    error500: 'an unexpected internal error',
    errorMessage: 'We encountered %{error_code}. Please try again.',
    unknownErrorMessage: 'We encountered an unknown error. Please try again.'
  },
  LoginLayout: {
    reimagineText: 'Reimagine the Future of Computing With Us',
    errorMessage: 'Your username or password was incorrect. Please try again.',
    changePWSuccessMessage: 'Your new password has been created.'
  },
  ChangePassword: {
    createPasswordLabel: 'Create a new password',
    existingPasswordLabel: 'Existing password',
    passwordLabel: 'Password',
    typePasswordLabel: 'Type password',
    retypePasswordLabel: 'Retype password',
    showLabel: 'Show',
    popOverTitle: 'Password must contain:',
    passwordLength: 'At least 8 characters long',
    oneLowerCase: 'At least 1 lowercase letter',
    oneUpperCase: 'At least 1 uppercase letter',
    oneNumber: 'At least 1 number',
    oneSpecialCharacter: 'At least 1 special character',
    differentPassword: 'Should not be the same as the default password',
    notSamePassword: 'New and confirm passwords do not match',
    notValidPassword: 'New or confirm password is not valid',
    submit: 'Submit'
  },
  HomePage: {
    acceptTerms: 'Accept Terms and Conditions',
    userGuideHelp: 'Having issues logging in?'
  }
};
